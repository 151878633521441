import { ICreateContactRequestStrapi } from 'utils/api';

interface IHsContext {
  hs_token?: string;
  submission_uri?: string;
  page_name?: string;
}
interface IHsValue {
  name: string;
  value: string;
}
export interface ISendMessageForm {
  name: string;
  message: string;
  email: string;
  phone: string;
  nda: boolean;
  context: string;
}

export const prepareDataForSendStrapi = async ({
  ...data
}: ISendMessageForm & IHsContext): Promise<ICreateContactRequestStrapi> => {
  return {
    ...data,
    date: new Date().toISOString(),
  };
};
export const transformDataToObject = (data: IHsValue[]) => {
  return data.reduce((acc: any, { name, value }) => {
    if (!acc[name]) {
      acc[name] = value;
    }
    return acc;
  }, {});
};
export const pickStrapiData = (data: IHsValue[]) => {
  const hsValues = transformDataToObject(data);
  const nda: string = hsValues.nda_radio ? hsValues.nda_radio : hsValues.do_you_need_our_nda_; // because on hs-prod and hs-dev they are varios
  const ndaValue = nda?.toLowerCase() === 'yes' ? true : false;
  return {
    name: hsValues.firstname,
    email: hsValues.email,
    phone: hsValues.phone,
    message: hsValues.message,
    nda: ndaValue,
    context: hsValues.hs_context,
  };
};

// export const prepareDataForSendStrapiWithFile = async ({
//   file,
//   ...rest
// }: ISendMessageForm & { recaptcha_token: string }): Promise<ICreateContactRequestStrapi> => {
//   if (file) {
//     const formData = new FormData();
//     formData.append('files', file[0]);
//     const res = await api.strapi.upload(formData);

//     return {
//       ...rest,
//       file: res.data[0].id,
//       date: new Date().toISOString(),
//     };
//   }
//   return {
//     ...rest,
//     file,
//     date: new Date().toISOString(),
//   };
// };
