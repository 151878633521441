import { useLocation } from '@reach/router';
import Cookies from 'universal-cookie';

export const useHubspotContext = () => {
  const location = useLocation();
  const submission_uri = location?.href;

  const pathNameArr = location?.pathname?.split('/');
  const page_name =
    pathNameArr[pathNameArr.length - 1] || pathNameArr[pathNameArr.length - 2] || 'main';

  const cookies = new Cookies();
  const hs_token: string = cookies.get('hubspotutk');

  return { submission_uri, page_name, hs_token };
};
