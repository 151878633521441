import React, { memo } from 'react';
import Top from '../top';
import styles from './booking.module.css';
import Embedded from './embedded';

interface Props {
  className?: string;
  onClick: () => void;
}

function Booking({ className, onClick }: Props) {
  return (
    <div className={className}>
      <Top title="Book a call" button="Send a message" onClick={onClick} />
      <div className={styles.content}>
        <Embedded />
      </div>
    </div>
  );
}

export default memo(Booking);
