import * as React from 'react';
import styles from './top.module.css';

interface Props {
  title: string;
  button: string;
  onClick: () => void;
}

function Top({ title, button, onClick }: Props) {
  return (
    <div className={styles.top}>
      <div className={styles.topSection}>
        <h2 className={styles.title}>{title}</h2>
        <button className={styles.btn} onClick={onClick}>
          {button}
        </button>
      </div>
    </div>
  );
}

export default Top;
