import React from 'react';
import { ISendMessageForm, pickStrapiData } from './helpers';

type WindowWithHubspot = Window & {
  hbspt: any;
};
interface IHubspotForm {
  sendDataToStrapi: (data: ISendMessageForm) => void;
}
declare const window: WindowWithHubspot;
const isBrowser = () => typeof window !== 'undefined';

const MESSAGE_ID = 'send_message';

export default function HubspotForm({ sendDataToStrapi }: IHubspotForm) {
  React.useEffect(() => {
    if (isBrowser()) {
      const script = document.createElement('script');
      script.src = `${process.env.GATSBY_HS_FORM_SRC}`;
      script.type = 'text/javascript';
      script.async = true;
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: `${process.env.GATSBY_HS_REGION}`,
            target: `#${MESSAGE_ID}`,
            portalId: `${process.env.GATSBY_HS_PORTAL_ID}`,
            formId: `${process.env.GATSBY_HS_FORM_ID}`,
            onBeforeFormSubmit: function (_: any, submissionValues: any) {
              const hsValues = pickStrapiData(submissionValues);
              sendDataToStrapi(hsValues);
            },
          });
        }
      });
    }
  }, []);

  return <div id={MESSAGE_ID}></div>;
}
