import * as React from 'react';
import { Swiper as SwiperClass } from 'swiper/types';
import { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
//styles
import 'swiper/swiper-bundle.min.css';
import styles from './slider.module.css';
import Booking from '../booking';
import SendMessage from 'components/send-message';
interface IFeedbackSlider {
  defaultIndex?: number;
  onChange?: (bool: boolean) => void;
}

function FeedbackSlider({ defaultIndex = 0 }: IFeedbackSlider) {
  const [swiperRef, setSwiperRef] = React.useState<SwiperClass | null>(null);
  const next = () => {
    swiperRef?.slideTo(1);
  };
  const prev = () => {
    swiperRef?.slideTo(0);
  };

  return (
    <Swiper
      modules={[Virtual]}
      spaceBetween={0}
      slidesPerView={1}
      virtual
      onSwiper={setSwiperRef}
      slideToClickedSlide={false}
      allowTouchMove={false}
      speed={500}
    >
      <SwiperSlide virtualIndex={defaultIndex}>
        <Booking onClick={next} className={styles.booking} />
      </SwiperSlide>
      <SwiperSlide virtualIndex={defaultIndex + 1}>
        <SendMessage onClick={prev} className={styles.send} />
      </SwiperSlide>
    </Swiper>
  );
}

export default FeedbackSlider;
